// Property : Stick element to top
$(function() {
    $(window).scroll(sticktothetop);
    sticktothetop();
});

function sticktothetop() {
    // TODO : To improve - PLD 2020/03/02
    const OFFSET = 35;
    const EXTRA_PADDING = 15 * 2;
    const HEIGHT_FROM_TOP = $('.Navbar').height() + $('.Header').height() + $('.PD__filterCtn').height() - OFFSET - EXTRA_PADDING;

    var window_top = $(window).scrollTop();

    if (window_top > HEIGHT_FROM_TOP) {
        $('.sticky').addClass('sticky--stick');
        $('.sticky__block').height($('.sticky').outerHeight());
    } else {
        $('.sticky').removeClass('sticky--stick');
        $('.sticky__block').height(0);
    }
}