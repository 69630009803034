import FormSubmission from '../properties/form/_form_submission.js';
import InputDate from '../input_date/_input_date.js';
import DatePicker from '../date_picker/_date_picker.js';
import Dropdown from '../dropdown/_dropdown.js';
import InputFile from '../input_file/_input_file.js';
import Password from '../properties/password_generator/_password_generator.js';

var Modal = {

    _context: $('.ModalWrapper:first'),

    // Init the modal
    init: function () {
        // Buttons with a [data-button] will trigger openModal() with the specified css selector
        $("*[data-modal]").on("click", function() {
            Modal.show($(this).attr("data-modal"));
        });
    },

    // Show the modal
    show: function (id) {

        // Set the new context
        Modal._context = $(id);

        // To auto-generate any autocomplete password in the modal
        if (Modal._context.filter(':not(#edit_form)').length) Password.init();

        // To init input files
        InputFile.init();

        // To init date pickers
        DatePicker.init();

        // Update the dropdowns placeholder style
        Dropdown.init(Modal._context);

        $(id).css('display');   // Hack to make browser detect modal element for ajax loaded elements to show CSS animations
        $(id).removeClass("ModalWrapper--hidden");

        // Buttons with a [data-action="close"] will trigger Modal.close() function
        $(".Button[data-action]", Modal._context).on("click.close", function() {
            if ($(this).attr("data-action") === "close")
                Modal.close($(this).closest('.ModalWrapper'))
        });

        $('.Button[data-action="next"]', Modal._context).on("click.next", function(e) {
            var form = $("form[action] .FormStep--active", Modal._context);

            var invalidElements = [];
            form.find('input, textarea, select').each(function() {
                if(!$(this)[0].checkValidity())
                    invalidElements.push($(this));
            });

            // Show very HTML5 errors
            FormSubmission.createAllErrors(form);

            // If no HTML5 errors left, submit the form
            if(!invalidElements.length && !e.isDefaultPrevented()) {
                Modal.nextStep();
            }
        });

        $('.Button[data-action="previous"]', Modal._context).on("click.previous", function() {
            Modal.previousStep($(this).closest('.ModalWrapper'));
        });

        $(".Modal__closeIcon, .ModalWrapper__background", Modal._context).on("click.close", function() {
            Modal.close($(this).parents('.ModalWrapper')[0]);
        });
    },

    // Close the modal
    close: function (ctx = null) {
        ctx = $(ctx) || Modal._context
        $(ctx).addClass("ModalWrapper--hidden");

        // Reset the errors
        $(ctx.find('.ErrorContainer')).remove();

        let modalCtn = ctx.closest('.EditModal__ctn');
        if (modalCtn.length) modalCtn.html("");

        Modal.reset();
    },

    // Reset the modal
    reset: function () {

        // 1. resets the steps
        if ($('.FormStep', Modal._context)) Modal.resetStep();

        // 2. resets the form
        $('form', Modal._context).trigger("reset");

        // 3. reset the inputdate
        if ($('.InputDate--separatedCtn').length) InputDate.reset();
    },

    goToStep: function(stepNb = 1) {
        $('.FormStep--active', Modal._context).removeClass('FormStep--active');
        $('.FormStep[data-step="' + stepNb + '"]', Modal._context).addClass('FormStep--active');
        Modal.updateStep();
    },

    // Goes to the next form step
    nextStep: function () {
        let activeStep = $('.FormStep--active', Modal._context);
        let nextStep = activeStep.next('.FormStep');
        if (nextStep.length) {
            activeStep.removeClass('FormStep--active');
            nextStep.addClass('FormStep--active');
            Modal.updateStep();
        }
    },

    // Goes to the previous form step
    previousStep: function (ctx) {
        let activeStep = $('.FormStep--active', ctx);
        let previousStep = activeStep.prev('.FormStep');
        if (previousStep.length) {
            activeStep.removeClass('FormStep--active');
            previousStep.addClass('FormStep--active');
            Modal.updateStep();
        }
    },

    // Last step
    updateStep: function () {
        let stepIndex   = $('.FormStep--active', Modal._context).index() + 1;
        let steps       = $('.FormStep', Modal._context).length;

        $('.Modal__content', Modal._context).scrollTop(0);

        let nextBtn     = $('.Button[data-action="next"]',      Modal._context),
            prevBtn     = $('.Button[data-action="previous"]',  Modal._context),
            submitBtn   = $('.Button[data-action="submit"]',    Modal._context);

        if (stepIndex == steps) {
            nextBtn.addClass('Button--hidden');
            submitBtn.removeClass('Button--hidden');
            if (steps > 1) prevBtn.removeClass('Button--hidden');
        } else if (stepIndex > 1 && stepIndex < steps) {
            submitBtn.addClass('Button--hidden');
            prevBtn.removeClass('Button--hidden');
            nextBtn.removeClass('Button--hidden');
        } else if (stepIndex == 1) {
            prevBtn.addClass('Button--hidden');
            nextBtn.removeClass('Button--hidden');
            submitBtn.addClass('Button--hidden');
        }
    },

    // Reset the steps of the modal
    resetStep: function () {
        $('.FormStep', Modal._context).removeClass('FormStep--active');
        $('.FormStep:first', Modal._context).addClass('FormStep--active');
        Modal.updateStep();
    },
}

Modal.init();

export default Modal;