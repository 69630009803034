import Popup from '../../components/popup/_popup.js';
import Modal from '../../components/modal/_modal.js';
import FormSubmission from '../../components/properties/form/_form_submission.js';
import DataBinding from '../../components/properties/data_binding/_data_binding';
import AdminUser from '../admin/admin_user';
import AdminCurrency from '../admin/admin_currency';

var columns = [];
var url     = $('#atom-data-table').data('url');
var countEl = $('.AMI.AMI--active .AMI__count');
var count   = +(countEl.text());
var datatable;

var Admin = {

    // Deletes an item
    delete: function(path, itemId) {
        var popup = $("#delete_popup");
        Popup.show(popup);
        Popup.confirm(popup, function (callback) {
            if (callback) {
                $.post(
                    Routing.generate(path + '_delete', {'item': itemId}),
                ).done(function(data) {
                    Popup.close(popup);
                    Admin.reloadDatatable();
                    Admin.decrement();
                });
            }
        });
    },

    initCurrencyRateForm () {
        if ($('#atom-data-table[data-path="admin_currency"]').length) {
            AdminCurrency.init()
        }
    },

    // Edits an item
    edit: function(path, itemId) {
        $.ajax({
            method: "POST",
            dataType : 'html',
            url: Routing.generate(path + '_show', {'item': itemId}),
            success: function (response) {
                $('.EditModal__ctn').html(response);
                Modal.init();
                AdminUser.init();
                Modal.show('.EditModal__ctn .ModalWrapper--hidden');
                FormSubmission.init($('.EditModal__ctn *[type=\'submit\']'));
                Admin.initCurrencyRateForm();
            }
        });
    },

    // Custom reload of the datatable
    reloadDatatable: function() {
        datatable = $('#atom-data-table').DataTable();
        $.ajax({
            url: url,
            success: function (response) {
                datatable.clear();
                datatable.rows.add(response.data).draw();
            }
        });
    },

    // Increment the item counter
    increment: function() {
        countEl.text(count += 1);
    },

    // Decrement the item counter
    decrement: function() {
        countEl.text(count -= 1);
    },

    findCharacterPos: function(str, pat, number){
        var i = -1;
        while (number-- && i++ < str.length){
            i = str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    },

    // Init the dataTable
    initDataTable: function() {
        $.ajax({
            url: url,
            success: function (data) {
                var columnNames = Object.keys(data.data[0]);
                for (var i in columnNames) {
                    // If data is an image
                    if (columnNames[i] === "logo") {
                        columns.push({
                            render: function(data, type, row) {
                                return row['logo'] !== null ? '<img src="/uploads/logos/' + row['logo'] + '" />' : '';
                            }
                        })
                    }
                    else {
                        columns.push({data: columnNames[i]});
                    }
                }
                columns.push(
                    {
                        data: 'id',
                        render: function(data, type, row) {
                            var render =
                                '<div class="ListActionButton">' +
                                    '<span class="IconButton wave-effect wave-effect--active" tabindex="0"><i class="IconButton__icon material-icons">more_vert</i></span>' +
                                        '<div class="ListAction" tabindex="0">'+
                                            '<ul class="ListAction__list ListAction__list--right" tabindex="0">';
                                            // If user has right, delete_popup form is on admin page
                                            if ($('#atom-data-table').is("[data-edit-label]")) {
                                                render += '<li class="ListAction__item" data-action="edit" data-id="'+data+'"><span class="ListAction__itemLink wave-effect"><i class="ListAction__itemIcon material-icons">mode_edit</i><span class="ListAction__itemTitle">' + $('#atom-data-table').attr('data-edit-label') + '</span></span></li>';
                                            } else if ($('#delete_popup').length) {
                                                render += '<li class="ListAction__item" data-action="edit" data-id="'+data+'"><span class="ListAction__itemLink wave-effect"><i class="ListAction__itemIcon material-icons">mode_edit</i><span class="ListAction__itemTitle">Edit info</span></span></li>';
                                                render += '<li class="ListAction__item" data-action="delete" data-id="'+data+'"><span class="ListAction__itemLink wave-effect"><i class="ListAction__itemIcon material-icons">delete_forever</i><span class="ListAction__itemTitle">Delete item</span></span></li>';
                                            }
                                        render += '</ul>' +
                                    '</div>' +
                                '</div>';
                            return render;
                        },
                        orderable: false,
                        searchable: false
                    }
                )
                Admin.loadDataTable(data.data, columns)
            }
        });
    },

    // Load data inside the dataTable
    loadDataTable: function(data, columns) {
        const tag = function () {
            return function ( data, type, row, meta ) {
                var dataR = '';
                if (data === true) {
                    dataR = '<span class="Tag Tag--blue">Yes</span>';
                } else if (data === false) {
                    dataR = '<span class="Tag Tag--gray">No</span>';
                } else {
                    dataR = data;
                }
                return dataR;
            }
        };

        datatable = $('#atom-data-table').DataTable({
            data: data,
            order: [[0, "desc"]],
            columnDefs: [
                {
                    targets: "_all",
                    render: tag()
                },
                { "width": "5%", "targets": 0 },
                { "width": "5%", "targets": -1 },
                {
                    "width": "15%",
                    "targets": -2,
                    "render" : function (data) {
                        let dataR = '';
                        if( data === null)
                        {
                            dataR = '';
                        }else if(data.length > 35) {
                            let cutOff = Admin.findCharacterPos(data, ',', 3);
                            dataR = data.substr(0, cutOff);
                            dataR += '…';
                        }else {
                            dataR = data;
                        }
                        return dataR;
                    }
                },
            ],
            columns: columns,
            language:{
                zeroRecords: "There is no items to show",
                paginate: {
                    "previous": "",
                    "next": "",
                }
            },
            info: false,
            "fnDrawCallback": function (oSettings) {
                // Disables pagination if there's only one page results
                var pgr = $(oSettings.nTableWrapper).find('.dataTables_paginate')
                oSettings._iDisplayLength > oSettings.fnRecordsDisplay() ? pgr.hide() : pgr.show();
            },
            initComplete: function(settings, json) {
                $('.DataTable').removeClass('DataTable--loading');

                // Edit / Delete actions
                $('#atom-data-table').on('click', '.ListAction__item[data-action][data-id]', function() {
                    let action = $(this).attr('data-action'),
                        path = $('#atom-data-table').data('path'),
                        itemId = $(this).attr('data-id');

                    if (action === "delete")
                        Admin.delete(path, itemId);
                    else if (action === "edit")
                        Admin.edit(path, itemId);
                });
            }
        });

        $('#Admin__search').on('keyup', function () {
            datatable.search(this.value).draw();
        });
    },

    init: function(){
        DataBinding.init();

        $('body').on('click', '#edit_form', function() {
            Admin.initLocations();
        });

    },

    initLocations: function() {
        DataBinding.edit();
    },

}

if ($('.DataTable__table--admin').length) {
    Admin.initDataTable();
    Admin.init();
}

export default Admin;