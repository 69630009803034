import Modal from '../../modal/_modal.js';
import Popup from '../../popup/_popup.js';
import Admin from '../../../pages/admin/admin.js';
import store from '../../../pages/referral_form/store.js';
import AdminCurrency from '../../../pages/admin/admin_currency.js';

var FormSubmission = {

    init: function (el) {
        el = el || $("*[type='submit']");
        /* Submit the form on click */
        el.on("click", function (e) {
            var formCtn = $(this).closest(".Dialog");
            var form = formCtn.find("form[action]");

            if ($(this).prev('form').length)
                form = $(this).prev("form[action]");

            FormSubmission.createFrontErrors(form, e, function (callback) {
                if (callback){
                    FormSubmission.submitToJson(form, e);
                }
            })
        });
    },

    createFrontErrors: (form, e, callback) => {
        if (form.length) {
            // Show very HTML5 errors
            FormSubmission.createAllErrors(form);

            // If no HTML5 errors left, submit the form
            callback(form[0].checkValidity() && !e.isDefaultPrevented());
        }
    },

    submitToJson: function (form, e) {
        // // Enable back the submit button after the submission.
        $(e.target).attr("disabled", true);

        var data = new FormData(form[0]);
        $.ajax({
            url: form.attr("action"),
            type: 'POST',
            dataType: 'json',
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response) {

                if (form.closest(".ReceivingForm").length) {
                    store.addData('referral', response.data)
                }

                // If form is in a modal
                if (form.closest(".ModalWrapper").length) {
                    Modal.close(form.closest(".ModalWrapper"));
                }

                // If form is in a popup
                if (form.parents(".Popup").length)
                    Popup.close();

                // Redirect to patient page if a patient has been created
                if ($('#modal_patient').length && $('#modal_patient #patient_redirection').is(':checked'))
                    window.location.replace(Routing.generate('patient_view', {'patient': response.data.id}));

                // Reset the form
                if (form.data('type') !== 'edit')
                    form.trigger("reset");

                for (var key in response.data) {
                    var fieldUpdate = $('[data-field-update="'+key +'"]');
                    if (fieldUpdate.length && fieldUpdate.text() != String(response.data[key])) {
                        fieldUpdate.text(response.data[key]);
                        fieldUpdate.trigger('change');
                    }
                }

                // Reloads table using datatabale ajax if automatic reload exists
                if ($(".DataTable__table--reload").length) {
                    $('#atom-data-table').DataTable().ajax.reload();
                }
                // else if admin page, reload using custom function
                else if ($(".DataTable__table").length) {
                    Admin.reloadDatatable();
                    if (form.data('type') !== 'edit')
                        Admin.increment();
                }

                if ($('.CurrencyRates__table').length) {
                    AdminCurrency.updateCurrencyRateList(response.data);
                }
            },
            error: function(response) {
                let responseData = response.responseJSON.data;
                let isStepAlreadyJumped = false;

                $(form.find('.ErrorContainer')).remove();

                for (var key in responseData) {
                    let errorCtn = $('<ul class="ErrorContainer"></ul>');
                    
                    // If an entity level error
                    if (Number.isInteger(+key)) {
                        form.prepend(errorCtn);
                        errorCtn.append(FormSubmission.getErrorLines(responseData[key]));
                    } else if (Array.isArray(responseData[key])) {
                        $(form.find('[name*="'+key+'"]')[0]).before(errorCtn);
                        errorCtn.append(FormSubmission.getErrorLines(responseData[key]));
                    } else {
                        for (var childKey in responseData[key]) {
                            errorCtn = $('<ul class="ErrorContainer"></ul>');
                            errorCtn.append(FormSubmission.getErrorLines(responseData[key][childKey]));
                            $(form.find('[name*="['+key+']['+childKey+']"]')[0]).before(errorCtn);

                            if (!isStepAlreadyJumped) {

                                // If in a modal step go to the error step
                                const input = form.find('[name*="['+key+']['+childKey+']"]')
                                const formStep = input.closest('.FormStep')
                                if (formStep) {
                                    Modal.goToStep(+(formStep.attr('data-step')));
                                }

                                isStepAlreadyJumped = true // to make sure to jump to the first error step
                            }
                        }
                    }
                }
            },
            complete: function() {
                // // Enable back the submit button after the submission.
                $(e.target).attr("disabled", false);

                // Manage Captcha reloading
                let captcha = $(form.find('[title*="captcha"]'));
                if(captcha) {
                    captcha.attr('src', captcha.attr('src') + new Date().getTime());
                }
            }
        });
    },

    getErrorLines: function (errors) {
        let errorsHtml = "";
        if (Array.isArray(errors))
            errors.forEach(error => { errorsHtml += FormSubmission.addErrorLine(error); });
        else
            errorsHtml += FormSubmission.addErrorLine(errors);
        return errorsHtml;
    },

    addErrorLine: function (errorMsg) {
        return '<li class="Error">'+ errorMsg +'</li>';
    },

    // Show error messages
    createAllErrors: function(form) {
        function showAllErrorMessages() {
            $(form.find('.ErrorContainer')).remove();

            // Find all invalid fields within the form.
            form.find( ":invalid" ).each( function( index, node ) {
                // Find the field's corresponding label
                var label = $("label[for=\"" + node.id + "\"]"),
                    // Opera incorrectly does not fill the validationMessage property.
                    message = node.validationMessage || 'Invalid value.';

                // Show Error Messages and prevent doublons
                if(form.find('[name*="'+node.name+'"]').parent().children('.ErrorContainer').length === 0)
                    $(form.find('[name*="'+node.name+'"]')[0]).before('<ul class="ErrorContainer"><li class="Error" data-key="'+ node.name + '">'+message+'</li></ul>');
            });
        }

        // Support Safari
        form.on( "submit", function( event ) {
            if ( this.checkValidity && !this.checkValidity() ) {
                 $( this ).find( ":invalid" ).first().focus();
                event.preventDefault();
            }
        });

        $("input[type=submit], button:not([type=button])", form )
            .on( "click", showAllErrorMessages());

        $("input", form).on("keypress", function(event) {
            var type = $(this).attr( "type" );
            if ( /date|email|month|number|search|tel|text|time|url|week/.test ( type )
            && event.keyCode == 13 ) {
                showAllErrorMessages();
            }
        });
    }
}

FormSubmission.init();

export default FormSubmission;