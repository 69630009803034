
var InputPassword = {

    // Inits the input passwords to add show toggle button
    init: function () {
        $('input[type=password]').each(function () {
            $(this).addClass('InputPassword').wrap('<div class="InputPassword__ctn"></div>');
        })
        $('.InputPassword__ctn').each(function () {
            $(this).append('<i class="material-icons InputPassword__toggleBtn">visibility_off</i>')
        })

        $('.InputPassword__toggleBtn').on('click', function() {
            var input = $(this).prev('.InputPassword');
            if (input.attr("type") ===  "password") {
                input.attr("type", "text");
                $(this).text("visibility")
            } else {
                input.attr("type", "password");
                $(this).text("visibility_off")
            }
        })
    },

    // Shows up the password of each InputPassword in the page
    show: function () {
        let input = $('.InputPassword__ctn .InputPassword')
        input.attr("type", "text");
        input.next('.InputPassword__toggleBtn').text("visibility");
    }
}

InputPassword.init();

export default InputPassword;