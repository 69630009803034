var InputFile = {

    // Init InputFile
    init: function () {

        // Loading all input files
        $('.InputText[type=file]').not(".InputFile__dropContainer .InputText[type=file]").each(function () {
            let inputFileHtml = '<label class="InputFile__dropContainer" for="'+ ($(this).attr('id') || '') +'">' +
                '<i class="InputFile__dropIcon material-icons">save_alt</i>' +
                '<span class="InputFile__fileName" data-file=""><b class="InputFile__label">File added : </b></span>' +
                '<span class="InputFile__dropLabel"><b class="InputFile__label">Upload the file</b> or drop it here...</span>' +
                $(this).addClass('InputFile')[0].outerHTML +
            '</label>';
            $(this).replaceWith(inputFileHtml);
        })

        var dropContainer = $('.InputFile__dropContainer');
        var fileInput = $('.InputFile', dropContainer);

        // Adds and removes drag over class effect
        dropContainer.bind('dragover', function(e) {
            $(this).addClass('InputFile__dropContainer--dragover');
            e.preventDefault();
        });
        dropContainer.bind('dragleave', function() {
            $(this).removeClass('InputFile__dropContainer--dragover');
        });

        fileInput.on("change", function () {
            InputFile.updateFilename($(this), dropContainer);
        });

        window.addEventListener("dragover", function(e){
            e = e || event;
            e.preventDefault();
        }, false);

        window.addEventListener("drop", function(e){
            e = e || event;
            if (e.target.className.includes('InputFile__')) { // check which element is our target
                dropContainer.removeClass('InputFile__dropContainer--dragover');
                fileInput[0].files = e.dataTransfer.files;
                fileInput.trigger('change');
            }
            e.preventDefault();
        }, false);
    },

    updateFilename: function (fileInput, dropContainer) {
        var file = fileInput.val().split("\\");
        var fileName = file[file.length-1];
        $('.InputFile__fileName', dropContainer).attr('data-file', fileName);
    }
}

InputFile.init();

export default InputFile;