import { dateFromYearMonthDay, dayFromDate, minTwoDigits, monthFromDate, yearFromDate } from "../../const/date_format";

var inputDay    = $(".InputDate--day"),
    inputMonth  = $(".InputDate--month"),
    inputYear   = $(".InputDate--year");

var InputDate = {

    // Init Separated InputDate
    init: function () {
        inputDay    = $(".InputDate--day"),
        inputMonth  = $(".InputDate--month"),
        inputYear   = $(".InputDate--year");
        $('.InputDate--separatedCtn').insertAfter('.InputDate--separatedValue');
        var dob = $('.InputDate--separatedValue').val();

        if (dob != undefined) {
            inputDay.val(minTwoDigits(dayFromDate(dob)));
            inputMonth.val(minTwoDigits(monthFromDate(dob)));
            inputYear.val(yearFromDate(dob));
        }

        // On keyup in input date field, focus on next if maxlength reached
        $(".InputDate--separated .InputText").on("keyup", function (e) {
            if ((e.which != 9 && e.which != 16)) { // Ik keypress is not tab
                if (this.value.length == $(this).attr('max-length')) {
                    $(this).closest('.InputContainer').next('.InputContainer').find('.InputText').focus();
                }
            }
        });

        // On input focus selects all text
        $(".InputDate--separated .InputText").on("focus", function () {
            this.setSelectionRange(0, this.value.length)
        });

        // On input focus selects all text
        $(".InputDate--separated .InputText").on("change", function () {
            InputDate.update();

            if (this.value.length < $(this).attr('max-length')) {
                var val = ("0" + ($(this).val())).slice(-$(this).attr('max-length'));
                $(this).val(val);
            }
        });

        // On dateformat button change, disable or enable date input
        $(".InputDate--separatedBtn input[type='radio']").on("change", function () {
            InputDate.change(this.value)
        });

        InputDate.update();
    },

    // Reset the InputDate
    reset: function () {
        $('.InputDate--separatedCtn input').removeAttr('disabled')
    },

    // Updates the invisible inputdate of the form
    update: function (id) {
        const day = minTwoDigits(inputDay.val() ?? 1);
        const month = minTwoDigits(inputMonth.val() ?? 1);
        const year = inputYear.val();        
        const fullDate = dateFromYearMonthDay(year, month, day);
        $('.InputDate--separatedValue').val(fullDate);
    },

    // Disables days and month for format picker and input dates fields
    enableYearMode: function () {
        cleanInput(inputDay)
        cleanInput(inputMonth)
        $(".InputDate--separatedBtn input[type='radio']").attr("disabled","disabled");
        $(".InputDate--separatedBtn input[type='radio'][value='3']").removeAttr("disabled").prop("checked", true);
    },

    // Enables days and month for format picker and input dates fields
    disableYearMode: function () {
        $(".InputDate--separatedBtn input[type='radio']").removeAttr("disabled");

        if(inputYear.val()){
            if(inputMonth.val()){
                if(inputDay.val()){
                    InputDate.change(1)
                }
                else {
                    InputDate.change(2)
                }
            }
            else {
                InputDate.change(3)
            }
        }
        else {
            InputDate.change(3)
        }
    },

    // Disables the selected fields
    change: function (value) {

        $(".InputDate--separatedBtn input[type='radio'][value='" + value + "']").prop("checked", true);

        InputDate.reset();

        if (value == '2') {
            cleanInput(inputDay)
        }
        else if (value == '3') {
            cleanInput(inputDay)
            cleanInput(inputMonth)
        }
    }
}


function cleanInput(elt) {
    elt.attr('disabled','disabled').val('')
}

export default InputDate;