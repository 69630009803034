import Login from '../../pages/login/login.js';

var Toaster = {
    close: function (toaster) {
        toaster.addClass("Toaster--hidden");
    },
    showUnexpectedIssue: function () {
        const errorToaster = $('.Toaster[data-type="unexpected_error"]');
        if (errorToaster != null) {
            errorToaster.show()
            errorToaster.removeClass('Toaster--hidden')
            setTimeout(() => {
                errorToaster.addClass('Toaster--hidden')
                errorToaster.hide()
            }, 5000)
        }
    }
}

$(".Toaster:not([data-type=nosignal])").on("click", function() {
    Toaster.close($(this));
});

export default Toaster;

const timeDelay = 5000;
const timeNoDelay = 0;

(function($) {
    var methods = {
        init: function(options) {
            methods.settings = $.extend({}, $.fn.flashNotification.defaults, options);

            setTimeout(
                function() {
                    $('.Toaster')
                        .show('fast')
                        .delay(methods.settings.hideDelay)
                        .hide('fast')
                    ;
                },
                500
            );

            methods.listenIncomingMessages();
        },

        /**
         * Listen to AJAX responses and display messages if they contain some
         */
        listenIncomingMessages: function() {
            $(document).ajaxComplete(function(event, xhr, settings) {
                // Only accept JSON response
                if (settings.dataTypes.includes("json")) {
                    var data = $.parseJSON(xhr.responseText);

                    if (data.messages) {
                        var messages = data.messages,
                            firstTime = true;

                        $.each(messages, function(key){
                            var array = messages[key];
                            setTimeout(
                                function() {
                                    methods.beforeAdd(key, array);
                                },
                                firstTime === true ? timeNoDelay : timeDelay
                            );
                            firstTime = false;
                        });
                    }
                }
            });
        },

        beforeAdd: function(key, array) {
                var i, type, firstTime;
                if (key === 'success') {
                    type = "valid";
                    firstTime = true;
                    $.each(array, function(key){
                        setTimeout(
                            function() {
                                methods.add(array[key],type);
                                // To improve : doesn't work if multiple [data-wait="true"] in active step
                                if ($('.Login').length)
                                Login.showStep($('.Login .Login__step--active *[data-link][data-wait="true"]').attr('data-link'));
                            },
                            firstTime === true ? timeNoDelay : timeDelay
                        );
                        firstTime = false;
                    });
                }

                if (key === 'info') {
                    type = "nosignal";
                    firstTime = true;
                    $.each(array, function(key){
                        setTimeout(
                            function() {
                                methods.addInfo(array[key]);
                            },
                            firstTime === true ? timeNoDelay : timeDelay
                        );
                        firstTime = false;
                    });
                }

                if (key === 'error') {
                    type = "error";
                    firstTime = true;
                    $.each(array, function(key){
                        setTimeout(
                            function() {
                                methods.add(array[key], type);
                            },
                            firstTime === true ? timeNoDelay : timeDelay
                        );
                        firstTime = false;
                    });
                }

        },
        add: function(message, type) {
            var flashMessageElt = methods.getBasicFlash(message, type);

            methods.addToList(flashMessageElt);
            methods.display(flashMessageElt);
        },

        getBasicFlash: function(message, type) {
            var flashMessageElt = $('<div></div>')
                .hide()
                .addClass('Toaster')
                .append($('<img class="Toaster__icon" src="/ressources/img/toaster/icon-' + type +  '.svg"/>'))
                .append($('<div class="Toaster__message"></div>').html(message))
            ;

            return flashMessageElt;
        },

        addToList: function(flashMessageElt) {
            flashMessageElt.appendTo($('.ToasterContainer'));
        },

        display: function(flashMessageElt) {
            setTimeout(
                function() {
                    flashMessageElt
                        .show('fast')
                        .delay(methods.settings.hideDelay)
                        .hide('fast', function() { $(this).remove(); } )
                    ;
                },
                500
            );
        }
    };

    $.fn.flashNotification = function(method) {
        // Method calling logic
        if (methods[method]) {
            return methods[ method ].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || ! method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' +  method + ' does not exist on jQuery.flashNotification');
        }
    };

    $.fn.flashNotification.defaults = {
        'hideDelay'         : 5000,
        'autoHide'          : false,
        'animate'           : false,
        'clickable'         : true,
        'vPosition'         : 'bottom',
        'hPosition'         : 'center',
        'fadeIn'            : 100,
        'fadeOut'           : 100,
    };
})(jQuery);

$('.ToasterContainer').flashNotification('init');