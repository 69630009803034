import Modal from '../../components/modal/_modal.js';
import Popup from '../../components/popup/_popup.js';
import FormSubmission from '../../components/properties/form/_form_submission';


var AdminCurrency = {

    init: function (ctx = null) {
        this.initCurrencyRateEvents();
    },

    updateCurrencyRateList: function (currencyRates) {
        if (currencyRates != null && currencyRates.length > 0) {
            $('.CurrencyRates__table tbody').empty()
            currencyRates.forEach(currencyRate => {
                $('.CurrencyRates__table tbody').append(this.getCurrencyRateRow(currencyRate))
            })
            this.initCurrencyRateListEvents();
        } else if (Array.isArray(currencyRates) && currencyRates.length === 0) {
            $('.CurrencyRates__table tbody').empty()
        }
    },
    getCurrencyRateRow: function (currencyRate) {
        return `<tr tabindex="1" data-currencyRate="${currencyRate.id}">
            <td>${currencyRate.id}</td>
            <td>${currencyRate.effectiveDate}</td>
            <td>${currencyRate.rate}</td>
            <td>
            <div class="ListActionButton">
                <span class="IconButton wave-effect wave-effect--active" tabindex="0"><i class="IconButton__icon material-icons">more_vert</i></span>
                <div class="ListAction" tabindex="0">
                    <ul class="ListAction__list ListAction__list--right" tabindex="0">
                        <li class="ListAction__item" data-action="edit" data-id="${currencyRate.id }">
                            <span class="ListAction__itemLink wave-effect">
                                <i class="ListAction__itemIcon material-icons">mode_edit</i><span class="ListAction__itemTitle">${window.Translation['editCurrencyRate']}</span>
                            </span>
                        </li>
                        <li class="ListAction__item" data-action="delete" data-id="${currencyRate.id }">
                            <span class="ListAction__itemLink wave-effect"><i class="ListAction__itemIcon material-icons">delete_forever</i>
                            <span class="ListAction__itemTitle">${window.Translation['deleteCurrencyRate']}</span></span>
                        </li>
                        </ul>
                </div>
            </div>
            </td>
        </tr>`;
    },

    initCurrencyRateListEvents: function () {
        let self = this;

        // Edit / Delete actions
        $('.ListAction__item[data-action][data-id]', '.CurrencyRates__table').off('click').on('click', function () {
            var currencyId = +$('.CurrencyRates').attr('data-currency-id');
            let action = $(this).data('action'),
                itemId = +$(this).data('id');

            if (action === "delete")
                self.deleteCurrencyRate(itemId, currencyId);
            else if (action === "edit")
                self.editCurrencyRate(itemId, currencyId);
        })
    },

    initCurrencyRateEvents: function () {
        $('.CurrencyRates__actions .Button[data-action="new-currencyrate"]').on('click', () => {
            var currencyId = +$('.CurrencyRates').attr('data-currency-id');
            this.addNewCurrencyRate(currencyId)
        });
        this.initCurrencyRateListEvents()
    },

    // Deletes a patient currencyRate
    deleteCurrencyRate: function(currencyRateId, currencyId) {
        var popup = $("#currencyRate_delete_popup");
        Popup.show(popup);
        Popup.confirm(popup, callback => {
            if (callback) {
                $.post(
                    Routing.generate('currency_rate_delete', { 'currency': currencyId, 'currencyRate': currencyRateId })
                ).done(response => {
                    Popup.close(popup);
                    AdminCurrency.updateCurrencyRateList(response.data)
                });
            }
        });
    },

    // Edits an item
    editCurrencyRate: function(currencyRateId, currencyId) {
        $.ajax({
            method: "POST",
            dataType : 'html',
            url: Routing.generate('currency_rate_show', {'currency': currencyId, 'currencyRate': currencyRateId}),
            success: (response) => {
                $('.CurrencyRateForm').html(response);
                Modal.show('.CurrencyRateForm .ModalWrapper');
                FormSubmission.init($('.CurrencyRateForm *[type=\'submit\']'));
            }
        });
    },

    addNewCurrencyRate: function (currencyId) {
        $.ajax({
            method: "POST",
            dataType : 'html',
            url: Routing.generate("currency_rate_show", {
                'currency': currencyId,
                'currencyRate': 0
            }),
            success: (response) => {
                $('.CurrencyRateForm').html(response);
                Modal.show('.CurrencyRateForm .ModalWrapper');
                FormSubmission.init($('.CurrencyRateForm *[type=\'submit\']'));
            }
        });
    },
}

export default AdminCurrency;