
import Vue from 'vue'

const store = {
    state: Vue.observable({
        referral: {},
        receiving: {},
    }),
    addField (formName, fieldName, required, ref) {
        this.state[formName].fields[fieldName] = {
            'required': required,
            '$': ref
        }
    },
    addForm (formName, ref) {
        this.state[formName] = {
            'fields': [],
            '$': ref,
            'data': null
        }
    },
    addData (formName, data) {
        this.state[formName].data = data
    }
};

export default store;
export const {state} = () => state