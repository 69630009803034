import Litepicker from 'litepicker';
import { DATE_FORMAT_LITEPICKER } from "../../const/date_format";
var DatePicker = {
  init: function () {
    // Init Input range component
    // Doc : https://wakirin.github.io/Litepicker/
    $(".InputDate:not(.InputDate--year)").each(function () {
      if ($(this).siblings(".litepicker-backdrop").length === 0) {
        var picker = new Litepicker({
          element: $(this)[0],
          format: DATE_FORMAT_LITEPICKER,
          lang: document.documentElement.lang,
          numberOfMonths: 1,
          numberOfColumns: 1,
          minDate: null,
          maxDate: new Date(),
          minDays: null,
          maxDays: null,
          selectForward: false,
          selectBackward: false,
          splitView: false,
          inlineMode: false,
          singleMode: true,
          autoApply: true,
          showWeekNumbers: false,
          showTooltip: true,
          disableWeekends: false,
          mobileFriendly: true,
        });
      }
    });
  },
};

DatePicker.init();

export default DatePicker;
