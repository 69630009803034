import Password from '../properties/password_generator/_password_generator.js';

var Popup = {

    show: function (id) {
        Password.init(); // To auto-generate any autocomplete password in the modal
        $(id).removeClass("PopupWrapper--hidden");
    },

    close: function () {
        $('.PopupWrapper').addClass("PopupWrapper--hidden");
        Popup.reset();
    },

    // Reset the modal
    reset: function () {
        $('.PopupWrapper form').trigger("reset");
        $('.Button[data-action="export"]').addClass('Button--disable');
    },

    confirm: function (popup, callback) {
        var cancelButton  = popup.find('.Popup__buttons .Button[data-action="close"]'),
            confirmButton = popup.find('.Popup__buttons .Button[data-action="validate"]');

        cancelButton.on("click", function() { callback(false); });
        confirmButton.unbind().click("click", function() { callback(true); });
    }
}

// Buttons with a [data-button] will trigger Popup.show() with the specified css selector
$("*[data-popup]").on("click", function () {
    if (!$(this).attr("disabled")) {
        Popup.show($(this).attr("data-popup"));
    }
});

// Buttons with a [data-action="close"] will trigger Popup.close() function
$(".Button[data-action]").on("click", function() {
    $(this).attr("data-action") === "close" ? Popup.close() : null;
});

$(".PopupWrapper__background").on("click", function() {
    Popup.close();
});

export default Popup;