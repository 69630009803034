var form = $('#add_form');

var AdminUser = {

    init: function(){
        form = $('#edit_form').length > 0 ? $('#edit_form') : $('#add_form');
        AdminUser.initFields(form);

        $('body').off('change.roles').on('change.roles', '#user_rolesForType', function() {
            AdminUser.initFields(form);
        });

        $('.Button[data-modal="#add_form"]').off('click.addForm').on('click.addForm', function() {
            form = $('#add_form')
            AdminUser.initFields(form);
        })
    },

    initFields: function(form) {

        let roles   = $('#user_rolesForType', form),
            region  = $('#user_region', form),
            country = $('#user_country', form),
            site    = $('#user_healthSites', form),
            partner = $('#user_partner', form);

        let regionIsEnabled     = $('option[value="ROLE_PUBLIC_HEALTH_OFFICER_REGIONAL_HQ"]', roles).is(':selected')
                                    || $('option[value="ROLE_REFERRAL_FOCAL_POINT_REGIONAL"]', roles).is(':selected')
                                    || $('option[value="ROLE_PUBLIC_HEALTH_OFFICER_COUNTRY"]', roles).is(':selected')
                                    || $('option[value="ROLE_REFERRAL_FOCAL_POINT_COUNTRY"]', roles).is(':selected')
                                    || $('option[value="ROLE_REFERRAL_FOCAL_POINT_SITE"]', roles).is(':selected') ;

        let countryPartnerIsEnabled   = $('option[value="ROLE_PUBLIC_HEALTH_OFFICER_COUNTRY"]', roles).is(':selected')
                                    || $('option[value="ROLE_REFERRAL_FOCAL_POINT_COUNTRY"]', roles).is(':selected')
                                    || $('option[value="ROLE_REFERRAL_FOCAL_POINT_SITE"]', roles).is(':selected');

        let siteIsEnabled       = $('option[value="ROLE_REFERRAL_FOCAL_POINT_SITE"]', roles).is(':selected');
        region.attr('disabled', !regionIsEnabled).attr('required', regionIsEnabled);
        country.attr('disabled', !countryPartnerIsEnabled).attr('required', countryPartnerIsEnabled);
        site.attr('disabled', !siteIsEnabled).attr('required', siteIsEnabled);
        partner.attr('required', countryPartnerIsEnabled);

        if (regionIsEnabled) {
            AdminUser.updateRegion(region, country, site)
        } else {
            region.val("");
        }

        countryPartnerIsEnabled? AdminUser.updateCountry(country, site) : country.val("");
        if (!siteIsEnabled) {
            AdminUser.uncheckSites()
        }

        region.off('change.region').on('change.region', function () {
            country.val("");
            AdminUser.uncheckSites()
            AdminUser.updateRegion(region, country, site);
        })

        country.off('change.country').on('change.',function () {
            AdminUser.uncheckSites()
            AdminUser.updateCountry(country, site);
        })
    },

    uncheckSites: function () {
        $(".Checkbox[id^='user_healthSites_']", form).prop("checked", false)
    },

    updateRegion: function(region, country){
        $("option[data-region]").show();
        let regionId = region.find(':selected').data('region');
        if (regionId != "") {
            $("option:not([data-region=" + regionId + "])", country).hide();
        }
    },

    updateCountry: function (country) {
        $(".Checkbox[id^='user_healthSites_']", form).closest('.CheckboxBox').show();
        if (country.val() != "")
            $(".Checkbox[id^='user_healthSites_']:not([data-country=" + country.val() + "])", form).closest('.CheckboxBox').hide()
    },
};

AdminUser.init();

export default AdminUser;
