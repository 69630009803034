// Property : Wave effect on buttons

[].forEach.call(document.querySelectorAll('.wave-effect') , function(elm){
    elm.innerHTML = '<div class="wave"></div>' + elm.innerHTML;
    elm.onmousedown = function (event) {
        var wave = elm.querySelector('.wave');
        var className = 'wave-effect--active';
        if (elm.classList.contains(className)) {
            elm.classList.remove(className);
        }
        wave.style.left = event.clientX - elm.offsetLeft + 'px';
        wave.style.top = event.clientY - elm.offsetTop + 'px';

        void elm.offsetWidth;
        elm.classList.add(className);
    }
})