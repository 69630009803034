
import InputPassword from '../../input_password/_input_password.js';

var Password = {

    _list_alpha   : "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    _list_int     : "0123456789",
    _list_spec    : "!,.@#-_{}[]",

    // Autocomplete inputs with [autocomplete=new-password] attribute
    init : function() {
        let autoPopulatePwd = $('input[autocomplete=new-password]');
        if (autoPopulatePwd.length) {
            autoPopulatePwd.val(Password.generate(8));
            InputPassword.show();
        }
    },

    // TODO : Secure this, as Math.random() is not crypto secured - PLD 2020-02-10
    // Generates a random password, containing alpha, int and special character
    generate : function(plength)
    {
        var temp    = '',
            len     = plength / 2,
            len     = len - 1,
            lenspec = plength - len - len;

        for (var i = 0; i < len; i++)
            temp += this._list_alpha.charAt(Math.floor(Math.random() * this._list_alpha.length));

        for (var i = 0; i < lenspec; i++)
            temp += this._list_spec.charAt(Math.floor(Math.random() * this._list_spec.length));

        for (var i = 0; i < len; i++)
            temp += this._list_int.charAt(Math.floor(Math.random() * this._list_int.length));

        temp = temp.split('').sort(function(){ return 0.5 - Math.random() }).join('');

        return temp;
    }
};

export default Password;