import DataBinding from '../../components/properties/data_binding/_data_binding';

var lastPage; // for CSS animation
var otherPartnerCtn = $('.InputContainer--otherPartner');
var otherPartnerField = otherPartnerCtn.children('.InputText');

var Login = {

    init: function() {

        DataBinding.init();

        $('.Login *[data-link]:not([data-wait="true"])').on('click', function() {
            Login.showStep($(this).attr('data-link'));
        });

        $('.Login__step[data-step=request-access] select[id*=_partner]').on('change', function () {
            if($(this).val() === '') {
                otherPartnerField.prop('required', true);
                otherPartnerCtn.show()
            } else {
                otherPartnerField.prop('required', false);
                otherPartnerCtn.hide();
            }
        })
    },

    // Show clicked step
    showStep: function(dataStep) {
        if (lastPage != undefined)
            lastPage.removeClass('Login__step--hiding')

        let page = $('.Login__step--active');
        page.removeClass('Login__step--active')
            .addClass('Login__step--hiding');

        lastPage = page;

        $('.Login__step[data-step="' + dataStep + '"]').addClass('Login__step--active');
    }
}

if ($('.Login').length) Login.init();

export default Login;